
export default {

    INDIVIDUAL_UNIT: 'janby/unit/',
    SERVING: 'janby/unit/serving/',

    UNIT_SLICE: 'janby/unit/slice/',
    UNIT_LEAF: 'janby/unit/leaf/',
    UNIT_PIECE: 'janby/unit/piece/',
    UNIT_CUP: 'janby/unit/cup/',
    UNIT_TABLESPOON: 'janby/unit/tablespoon/',
    UNIT_TEASPOON: 'janby/unit/teaspoon/',
    UNIT_CALORY: 'janby/unit/calory/',

    TIME: 'janby/unit/time/', // Seconds
    TIME_DURATION: 'janby/unit/duration/', // Seconds
    TIME_SECOND: 'janby/unit/time/s/', // Seconds
    TIME_MINUTE: 'janby/unit/time/min/', // Minutes
    TIME_HOUR: 'janby/unit/time/h/', // Hours
    TIME_DAY: 'janby/unit/time/d/', // Days
    TIME_WEEK: 'janby/unit/time/w/', // Weeks
    TIME_MONTH: 'janby/unit/time/m/', // Months
    TIME_YEAR: 'janby/unit/time/y/', // Years

    TEMPERATURE: 'janby/unit/temperature/', // Celsius
    TEMPERATURE_CELSIUS: 'janby/unit/temperature/C/', // Celsius
    TEMPERATURE_FAHRENHEIT: 'janby/unit/temperature/F/', // Fahrenheit
    TEMPERATURE_KELVIN: 'janby/unit/temperature/K/', // Kelvin

    LENGTH: 'janby/unit/length/', // Meter
    LENGTH_METER: 'janby/unit/length/m/', // Meter
    LENGTH_CENTIMETER: 'janby/unit/length/cm/', // Centimeter
    LENGTH_MILLIMETER: 'janby/unit/length/mm/', // Millimeter
    LENGTH_KILOMETER: 'janby/unit/length/km/', // Kilometer
    LENGTH_INCH: 'janby/unit/length/in/', // Inch
    LENGTH_FOOT: 'janby/unit/length/ft/', // Foot
    LENGTH_YARD: 'janby/unit/length/yd/', // Yard
    LENGTH_MILE: 'janby/unit/length/mi/', // Mile

    MASS: 'janby/unit/mass/', // Kilogram
    MASS_KILOGRAM: 'janby/unit/mass/kg/', // Kilogram
    MASS_GRAM: 'janby/unit/mass/g/', // Gram
    MASS_MILLIGRAM: 'janby/unit/mass/mg/', // Milligram
    MASS_OUNCE: 'janby/unit/mass/oz/', // Ounce
    MASS_POUND: 'janby/unit/mass/lb/', // Pound


    getUnitsStructure () {
        return {
            [this.INDIVIDUAL_UNIT]: [this.INDIVIDUAL_UNIT],
            [this.SERVING]: [this.SERVING],
            [this.UNIT_SLICE]: [this.UNIT_SLICE],
            [this.UNIT_LEAF]: [this.UNIT_LEAF],
            [this.UNIT_PIECE]: [this.UNIT_PIECE],
            [this.UNIT_CUP]: [this.UNIT_CUP],
            [this.UNIT_TABLESPOON]: [this.UNIT_TABLESPOON],
            [this.UNIT_TEASPOON]: [this.UNIT_TEASPOON],
            [this.UNIT_CALORY]: [this.UNIT_CALORY],
            [this.TEMPERATURE]: [this.TEMPERATURE_CELSIUS, this.TEMPERATURE_FAHRENHEIT], // , UnitTypes.TEMPERATURE_KELVIN],
            [this.TIME]: [this.TIME],
            [this.MASS]: [this.MASS_GRAM, this.MASS_MILLIGRAM, this.MASS_KILOGRAM, this.MASS_OUNCE, this.MASS_POUND],
            [this.LENGTH]: [this.LENGTH_METER, this.LENGTH_CENTIMETER, this.LENGTH_KILOMETER, this.LENGTH_INCH, this.LENGTH_FOOT, this.LENGTH_YARD, this.LENGTH_MILE],
        }
    },
}